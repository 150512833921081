'use strict'

export default {
  // logo: require('./src/logo.svg'),
  // arrowDownCircle: require('./src/arrow-down-circle.svg'),
  // arrowDownLeft: require('./src/arrow-down-left.svg'),
  // arrowDownRight: require('./src/arrow-down-right.svg'),
  // arrowDown: require('./src/arrow-down.svg'),
  // arrowLeftCircle: require('./src/arrow-left-circle.svg'),
  // arrowLeft: require('./src/arrow-left.svg'),
  // arrowRight: require('./src/arrow-right.svg'),
  // arrowRightCircle: require('./src/arrow-right-circle.svg'),
  // arrowUpCircle: require('./src/arrow-up-circle.svg'),
  // arrowUpLeft: require('./src/arrow-up-left.svg'),
  // arrowUpRight: require('./src/arrow-up-right.svg'),
  // arrowUp: require('./src/arrow-up.svg'),
  // checkCircle: require('./src/check-circle.svg'),
  // check: require('./src/check.svg'),
  // chevronDown: require('./src/chevron-down.svg'),
  // chevronLeft: require('./src/chevron-left.svg'),
  // chevronRight: require('./src/chevron-right.svg'),
  // chevronUp: require('./src/chevron-up.svg'),
  // copy: require('./src/copy.svg'),
  // eyeOff: require('./src/eye-off.svg'),
  // eye: require('./src/eye.svg'),
  // info: require('./src/info.svg'),
  // minus: require('./src/minus.svg'),
  // sun: require('./src/sun.svg'),
  // moon: require('./src/moon.svg'),
  // moreHorizontal: require('./src/more-horizontal.svg'),
  // moreVertical: require('./src/more-vertical.svg'),
  // send: require('./src/send.svg'),
  // smile: require('./src/smile.svg'),
  // search: require('./src/search.svg'),
  // upload: require('./src/upload.svg'),
  // video: require('./src/video.svg'),
  // x: require('./src/x.svg'),
  // plus: require('./src/plus.svg')
}
